@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .text-gradient {
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

::selection {
    background-color: rgb(255, 200, 49) !important;
    color: white;
}